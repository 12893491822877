<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? $t('Update Currency') : $t('Add New Currency') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Code')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.code"
                      :label="`* ${$t('Code')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Symbol')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.symbol"
                      :label="`* ${$t('Symbol')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Ex.rate')"
                    rules="required"
                  >
                    <v-currency-field
                      v-model="formData.ex_rate"
                      :label="`* ${$t('Ex.rate')}`"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  {{ editMode ? 'mdil-check' : 'mdil-plus' }}
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  methods: {
    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      if (this.editMode) {
        axios.put(`currency/${this.formData.id}/`, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('update-table-data')
        })
      } else {
        axios.post('currency/', this.formData).then(() => {
          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      }
    }
  },
}
</script>
